import { Box, Heading } from '@chakra-ui/react';
import * as React from 'react';
import { Seo } from '../../components/Seo';

const NotFoundPage = () => {
    return (
        <Box as="main">
            <Seo />

            <Heading>Nie ma takiej strony</Heading>
        </Box>
    );
};

export default NotFoundPage;
